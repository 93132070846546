import React, { Fragment, useEffect, useState } from "react";
import Title from "../../../text/title";
import Toaster from "../../../common/Toaster";
import LinkText from "../../../text/linkText";
import "./style.scss";
import InputWithIcon from "../../../input/inputWithIcon";
import NavRight from "../../../button/navRight";
import NavLeft from "../../../button/navLeft";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import { updateCurrentBrand as updateBrand } from "../../../../actions/brand/action";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../../static/images/common/customer-form-lottie.json";
import { register } from "../../../../actions/user/action";

const StepEight = ({ brandId, brandWebsite, updateBrand,userState }) => {
 
  const [isMobile, setMobile] = useState(false);
  const [localstorageUserState, setLocalstorageUserState] = useState({});
  const [website, setBrandWebsite] = useState("");
  const [refresh, doRefresh] = useState(0);
  const [toastrMsg, setToastrMsg] = useState("");

  useEffect(() => {
    if (window.innerWidth < 576) setMobile(true);
    const handleWindowSizeChange = () => {
      window.innerWidth < 576 ? setMobile(true) : setMobile(false);
    };
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const localstorageUser = JSON.parse(localStorage.getItem("registeringUser"));
    setLocalstorageUserState(localstorageUser);
    setBrandWebsite(
      localstorageUser && localstorageUser.brandWebsite
        ? localstorageUser.brandWebsite
        : ""
      )

    if (!localstorageUser) {
      if (!localstorageUser || !localstorageUser.name) {
        navigate("/onboarding/user-details");
      } else if (!localstorageUser || !localstorageUser.state) {
        navigate("/onboarding/brand-location");
      } else if (!localstorageUser || !localstorageUser.age) {
        navigateBack();
      }
    }
  }, []);

  // useEffect(() => {
  //  console.log(userState)
  //  console.log(brandId)
  // }, [userState,brandId])
  

  const onSubmit = async () => {
    // if (!website) {
    //   setToastrMsg("Please enter a Website");
    //   doRefresh((prev) => prev + 1);
    //   return;
    // }
    localstorageUserState.brandWebsite = website;
    localStorage.setItem("registeringUser", JSON.stringify(localstorageUserState));
    var updateBrandResonse = await updateBrand(brandId, { website },true);
    if (updateBrandResonse && !updateBrandResonse.error) {
      navigate("/onboarding/registration-successful");
    } else {
      setToastrMsg("Unable to add Website");
      doRefresh((prev) => prev + 1);
    }
  };

  const navigateBack = () => {
    navigate("/onboarding/brand-name");
  };
  return (
    <Fragment>
      <Toaster refresh={refresh} type="error" message={toastrMsg} />
      <div className="onboarding row website">
        <div className="top">
          <img src="/images/common/plus_new_logo.svg" />
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12 r-image">
          <div className="d-flex justify-content-center margin-top-img lottie">
            {!isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 200, height: 200, margin: "auto" }}
              />
            )}
            {isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 120, height: 120, margin: "auto" }}
              />
            )}
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12  step-content">
          <div className="brand-focus-heading">
            <Title text="Your brand website?" />
          </div>

          <div className="row brand-name">
            <InputWithIcon
              placeholder="companywebsite.com"
              icon="/images/common/company.svg"
              value={website}
              onChange={(e) => setBrandWebsite(e.target.value)}
            />
            <div className="name-subtext">
              If you don’t have one yet, skip it.
            </div>
          </div>
        </div>
        <div className={!isMobile ? "row" : ""}>
          <div className="col-12 col-md-6 col-sm-6 col-xl-6 to-right hpx"></div>
          <div className="col-12 col-md-6 col-sm-6 col-xl-6 to-left p-30-step-eight">
            <div className="nav-conatiner">
              <div>
                <NavLeft onClick={() => navigateBack()} />
                <span className="nav-text" onClick={() => navigateBack()}>
                  Back
                </span>
              </div>
              <div>
                <span className="nav-text" onClick={() => onSubmit()}>
                  Next
                </span>
                <NavRight onClick={() => onSubmit()} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({ brand,register }) => ({
  brandWebsite: brand.website,
  brandId: brand.brandId,
  userState:register
});

export default connect(mapStateToProps, { updateBrand,register })(StepEight);
