import { Brand } from "../APISDK";

export const addBrand = async (payload) => Brand.addBrand(payload);
export const updateBrand = async (brandId, payload) =>
  Brand.updateBrand(payload, brandId);
export const getBrandIdForUser = async () => Brand.getBrandIdForUser();
export const sendEmailtoUser = async (payload) => Brand.sendEmailtoUser(payload);
export const updateAddress = async (payload) => Brand.updateAddress(payload);
export const deleteAddress = async (payload) => Brand.deleteAddress(payload);
export const updateUser = async (payload) => Brand.updateUser(payload);
export const updateUserPassword = async (payload) => Brand.updateUserPassword(payload);
export const sendEmailtoUserApproval = async (payload) => Brand.sendEmailtoUserApproval(payload);



