import {
  addBrand,
  updateBrand,
  getBrandIdForUser,
  sendEmailtoUser,
  updateAddress,
  deleteAddress,
  updateUser,
  updateUserPassword,
  sendEmailtoUserApproval
} from "../../../api/brand";
import { types } from "./types";

export const addNewBrand = (data) => async (dispatch) => {
  try {
    const response = await addBrand(data);
    if (!response || !response.success) {
      //toaster
      throw new Error("Adding Brands Failed");
    } else {
      dispatch({
        type: types.SET_BRAND,
        payload: { brandId: response.brandId, ...data },
      });
      return { error: false, errorData: null, responseData: response };
    }
  } catch (error) {
    return { error: true, errorData: error, responseData: null };
  }
};

export const updateCurrentBrand =
  (brandId, data, sendEmail) => async (dispatch) => {
    try {
      let brand;
      if (!brandId) {
        brand = await getBrandIdForUser();
        if (brand.success) brandId = brand.brandId;
      }
      if (brandId && sendEmail) {
        // await sendEmailtoUserApproval({brandId})
        await sendEmailtoUser({ brandId });
      }
      const response = await updateBrand(brandId, data);
      if (!response || !response.success) {
        //toaster
        throw new Error("Updating Brands Failed");
      } else {
        dispatch({
          type: types.SET_BRAND,
          payload: { ...data },
        });
        return { error: false, errorData: null, responseData: null };
      }
    } catch (error) {
      return { error: true, errorData: error, responseData: null };
    }
  };

export const updateAddressHandler = (data) => async (dispatch) => {
  try {
    const response = await updateAddress(data);
    if (!response || !response.success) {
      //toaster
      throw new Error("Address Failed!");
    } else {
      return { error: false, errorData: null, responseData: response };
    }
  } catch (error) {
    return { error: true, errorData: error, responseData: null };
  }
};

export const deleteAddressHandler = (data) => async (dispatch) => {
  try {
    const response = await deleteAddress(data);
    if (!response || !response.success) {
      //toaster
      throw new Error("Address Failed!");
    } else {
      return { error: false, errorData: null, responseData: response };
    }
  } catch (error) {
    return { error: true, errorData: error, responseData: null };
  }
};

export const updateUserHandler = (data) => async (dispatch) => {
  try {
    const response = await updateUser(data);
    if (!response || !response.success) {
      //toaster
      throw new Error("Address Failed!");
    } else {
      return { error: false, errorData: null, responseData: response };
    }
  } catch (error) {
    return { error: true, errorData: error, responseData: null };
  }
};

export const updateUserPasswordHandler = (data) => async (dispatch) => {
  try {
    const response = await updateUserPassword(data);
    if (!response || !response.success) {
      if(response.data){
        return { error: true, errorData: response.data, responseData: null };
      }

      throw new Error("Password change Failed")
      
    } else {
      return { error: false, errorData: null, responseData: response };
    }
  } catch (error) {
    return { error: true, errorData: error, responseData: null };
  }
};

